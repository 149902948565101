.what {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.what > * {
    margin: 20px auto;
}

.what > hr {
    width: 80vw;
}

.title {
    padding-top: 20px;
    text-align: center;
}

.explication {
    width: 80vw;
    margin-left: auto;
    margin-right: auto;
}

.explication > * {
    margin: 0px auto 20px auto;
}

.explication > h2 {
    margin:0 10px 0 0;
}

.cardImg {
    text-align: center;
    width: 35vw;
    min-width: 200px;
}